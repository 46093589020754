/* Main container styling */
.not-found-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  /* min-height: 100vh; */
  /* background-color: #f0f0f0; */
  padding: 20px;
  margin: 0;
}

/* Image styling */
.not-found-image {
  max-width: 300px;
  height: auto;
  margin-bottom: 20px;
  animation: fadeIn 1.5s ease-in-out;
}

/* Title styling */
/* .not-found-title {
  font-size: 3rem;
  color: #ff4d4d;
  margin-bottom: 10px;
  animation: slideInFromTop 1s ease-in-out;
} */

/* Message styling */
.not-found-message {
  font-size: 1.2rem;
  color: #555;
  margin-bottom: 30px;
  animation: fadeIn 2s ease-in-out;
}

/* Button styling */
.not-found-buttons {
  display: flex;
  gap: 20px;
}

.not-found-button {
  text-decoration: none;
  background-color: #620f8b;
  color: white;
  padding: 10px 20px;
  border-radius: 5px;
  font-size: 1.1rem;
  transition: background-color 0.3s ease;
}

.not-found-button:hover {
  background-color: #4c096c;
}

/* Animations */
@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@keyframes slideInFromTop {
  from {
    transform: translateY(-50px);
    opacity: 0;
  }
  to {
    transform: translateY(0);
    opacity: 1;
  }
}

/* Responsive design */
@media (max-width: 600px) {
  .not-found-title {
    font-size: 2rem;
  }

  .not-found-message {
    font-size: 1rem;
  }

  .not-found-button {
    font-size: 1rem;
    padding: 8px 16px;
  }
}
