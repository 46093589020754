/* Login view  */

/* Container for the login form */
.login-container {
  width: 100%;
  max-width: 400px; /* Limit the width of the form */
  margin: 50px auto; /* Center the form horizontally */
  padding: 20px;
  background-color: #f9f9f9; /* Light background for contrast */
  border-radius: 8px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1); /* Optional shadow */
  margin-top: 60px;
}
/* Mobile layout */
@media (max-width: 480px) {
  .login-container {
    width: 90%; /* Make the login form narrower */
    padding: 15px;
    margin-top: 60px; /* Ensure there is no extra margin */
  }

  button[type="submit"],
  .password-container input,
  .password-container .toggle-password-btn,
  .login-form input {
    font-size: 14px; /* Slightly smaller text size for mobile */
    padding: 8px; /* Smaller padding to fit smaller screens */
  }

  /* Ensure the password input and show/hide button have matching height */
  .password-container input {
    padding: 8px;
    min-height: 40px; /* Set minimum height for consistency */
    box-sizing: border-box; /* Ensure the padding and border don't affect width/height */
  }

  /* Style the show/hide password button */
  .toggle-password-btn {
    padding: 8px;
    border: 1px solid #ccc;
    border-left: none; /* Remove the border between input and button */
    border-radius: 0 4px 4px 0; /* Round right corners only */
    background-color: #f1f1f1;
    cursor: pointer;
    font-size: 14px;
    color: #620f8b;
    width: auto; /* Ensure the button's width is minimal and does not stretch */
    box-sizing: border-box; /* Ensure padding doesn't affect total width */
    height: 40px; /* Explicitly set height to match the input field */
    /* margin-bottom: 10px; */
  }
}

/* Style the login form */

/* Form groups for spacing */
.form-group {
  margin-bottom: 10px;
  width: 100%; /* Ensure form group takes full width */
}

/* Labels */
.form-group label {
  display: block;
  margin-bottom: 5px;
  font-weight: bold;
  width: 100%;
  text-align: left; /* Ensure labels are left-aligned */
}

/* Input fields */
.login-form input {
  width: 100%;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 4px;
  font-size: 16px;
  box-sizing: border-box; /* Ensure padding doesn't affect total width */
  margin: 0; /* Remove any unnecessary margin */
}

/* Password input and show/hide button container */
.password-container {
  display: flex;
  align-items: center;
  width: 100%; /* Ensure the container takes up the full width */
  box-sizing: border-box; /* Ensure padding and border do not affect width */
  margin-bottom: 20px; /* Remove any unnecessary margin */
  padding: 0; /* Ensure there's no padding between the container and elements */
}

/* Ensure the input and button take up the available space */
.password-container input {
  flex-grow: 1; /* Make sure the input grows to take up available space */
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 4px 0 0 4px; /* Round left corners only */
  font-size: 16px;
  box-sizing: border-box; /* Ensure padding doesn't affect total width */
  width: 100%; /* Make sure it fills the container */
}

/* Style the show/hide password button */
.toggle-password-btn {
  padding: 10px;
  border: 1px solid #ccc;
  border-left: none; /* Remove the border between input and button */
  border-radius: 0 4px 4px 0; /* Round right corners only */
  background-color: #f1f1f1;
  cursor: pointer;
  font-size: 16px;
  color: #620f8b;
  width: auto; /* Ensure the button's width is minimal and does not stretch */
  box-sizing: border-box; /* Ensure padding doesn't affect total width */
}

/* Submit button */
button[type="submit"] {
  background-color: #620f8b;
  color: white;
  border: none;
  padding: 10px 15px;
  border-radius: 4px;
  cursor: pointer;
  font-size: 16px;
  width: 100%; /* Make the button take full width */
  box-sizing: border-box;
  text-align: center;
}

/* Optional error message styling */
.error-message {
  color: red;
  font-weight: bold;
  text-align: center;
}

.register-success-message {
  font-weight: bold;
  color: green;
}

/* Modal and Terms section */

/* Form group layout */
.form-group2 {
  display: flex; /* Arrange items horizontally */
  align-items: center; /* Center items vertically */
  gap: 8px; /* Space between elements */
  margin-bottom: 1rem; /* Add some space between groups */
  margin-top: 1.5rem;
}

/* Label styling */
.custom-checkbox-label {
  display: flex;
  align-items: center; /* Center elements vertically */
  cursor: pointer;
  font-size: 16px;
  gap: 6px; /* Space between checkbox and text */
  white-space: nowrap; /* Prevent text wrapping */
}

/* Hide the default checkbox */
.custom-checkbox {
  display: none;
}

/* Custom checkbox box styling */
.custom-checkbox-box {
  width: 20px;
  height: 20px;
  border: 2px solid #620f8b; /* Purple border */
  border-radius: 4px;
  background-color: white;
  position: relative;
  margin-left: 0.5rem;
}

/* Checkmark appears when checkbox is checked */
.custom-checkbox-box:checked + .custom-checkbox-box::after {
  content: "";
  position: absolute;
  top: 4px;
  left: 6px;
  width: 6px;
  height: 12px;
  border: solid #620f8b;
  border-width: 0 2px 2px 0;
  transform: rotate(45deg);
}

/* Terms link styling */
.terms-link {
  color: blue;
  text-decoration: underline;
  cursor: pointer;
}

/* Modal styling */
.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.modal-content {
  background: white;
  padding: 20px;
  border-radius: 10px;
  max-width: 500px;
  text-align: center;
}

.terms-content {
  max-height: 400px;
  overflow-y: scroll;
  padding: 10px;
  border: 1px solid #ccc;
  margin-bottom: 1rem;
  text-align: left;
}

  /* ForgotPassword link */
  .forgotpw-link {
    display: inline-block;
    margin-bottom: 10px;
    color: #007bff; /* Linkin väri */
    text-decoration: none; /* Poista alleviivaus */
    font-weight: bold; /* Lihavointi */
    transition: color 0.3s ease; /* Sileä värin muutos */
  }

  .forgotpw-link-link:hover {
    color: #0056b3; /* Linkin väri hover-tilassa */
    text-decoration: underline; /* Alleviivaus hover-tilassa */
  }
