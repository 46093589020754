/* MoodEnergyService */
  .track-mood-energy-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 30px;
    max-width: 600px;
    margin: 50px auto;
    background-color: #f9f9f9; 
    box-shadow: 0 10px 20px rgba(0, 0, 0, 0.1); 
    border-radius: 12px; 
    transition: transform 0.3s, box-shadow 0.3s; 
    text-align: left;
  }
  
  .track-mood-energy-container h2 {
    font-size: 24px;
    margin-bottom: 20px;
  }
  
  .track-mood-energy-container form {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  
  .track-mood-energy-container label {
    margin-top: 10px;
  }
  
  .slider-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 10px;
  }
  
  .slider {
    -webkit-appearance: none;
    appearance: none;
    width: 300px;
    height: 25px;
    border-radius: 5px;
    background: linear-gradient(to right, blue, green, red);
    outline: none;
    opacity: 0.7;
    transition: opacity 0.2s;
  }
  
  .slider:hover {
    opacity: 1;
  }
  
  .slider::-webkit-slider-thumb {
    -webkit-appearance: none;
    appearance: none;
    width: 25px;
    height: 25px;
    border-radius: 50%;
    background: #4CAF50;
    cursor: pointer;
  }
  
  .slider::-moz-range-thumb {
    width: 25px;
    height: 25px;
    border-radius: 50%;
    background: #4CAF50;
    cursor: pointer;
  }
  .valence-slider {
    -webkit-appearance: none;
    appearance: none;
    width: 300px;
    height: 25px;
    border-radius: 5px;
    background: linear-gradient(to right, #A8E6A1, #FFD700, #FFA500, #FF4500);
    outline: none;
    opacity: 1;
    transition: opacity 0.2s;
  }
  
  .valence-slider:hover {
    opacity: 1;
  }
  
  .valence-slider::-webkit-slider-thumb {
    -webkit-appearance: none;
    appearance: none;
    width: 25px;
    height: 25px;
    border-radius: 50%;
    background: #4CAF50;
    cursor: pointer;
  }
  
  .valence-slider::-moz-range-thumb {
    width: 25px;
    height: 25px;
    border-radius: 50%;
    background: #4CAF50;
    cursor: pointer;
  }


  .forgot-button {
    padding: 5px 10px;
    margin-top: 10px;
    margin-bottom: 10px;
    border: none;
    border-radius: 4px;
    background-color: #007bff;
    color: white;
    font-size: 13px;
    cursor: pointer;
    transition: background-color 0.3s;
  }

  .forgot-button:hover {
    background-color: #0056b3;
  }

  .forgotten-container {
    margin-top: 20px;
    padding: 10px;
    background-color: #e9ecef;
    border-radius: 4px;
  }

  .forgotten-text {
    margin-bottom: 5px;
    font-weight: bold;
    font-size: 16px;
  }

.track-mood-energy-container input[type="date"],
.track-mood-energy-container input[type="time"] {
  padding: 10px;
  margin-top: 5px;
  border: 1px solid #ccc;
  border-radius: 4px;
  font-size: 16px;
}

  /* MoodEnergyChart */
  .mood-energy-chart-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 30px;
    max-width: 600px;
    margin: 50px auto;
    background-color: #f9f9f9; 
    box-shadow: 0 10px 20px rgba(0, 0, 0, 0.1); 
    border-radius: 12px; 
    transition: transform 0.3s, box-shadow 0.3s; 
    text-align: left;
  }
  
  .mood-energy-chart-container h2 {
    font-size: 24px;
    margin-bottom: 20px;
  }
  
  .mood-energy-chart {
    /* width: 2000px; */
    width: 100%;
    max-width: 800px;
    height: 400px;
    /* overflow-x: scroll; */
  }

  .date-picker-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 20px;
  }
  
  .date-picker-container label {
    font-size: 16px;
    margin-bottom: 5px;
  }
  
  .date-picker {
    width: 200px;
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 4px;
    font-size: 16px;
    text-align: center;
    cursor: pointer;
  }
  
  .date-picker::placeholder {
    color: #888;
  }

  .message {
    font-weight: bold;
    color: #620f8b;
  }
  
  .error-message {
    font-weight: bold;
    color: red;
  }

  /*MoodEnergySummaryChart.js*/
  .mood-energy-summary-chart-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 30px;
    max-width: 600px;
    margin: 50px auto;
    background-color: #f9f9f9; 
    box-shadow: 0 10px 20px rgba(0, 0, 0, 0.1); 
    border-radius: 12px; 
    transition: transform 0.3s, box-shadow 0.3s; 
    text-align: left;
  }

  .mood-energy-summary-chart {
    width: 100%;
    max-width: 800px;
    height: 400px;
  }

  
  .controls {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 20px;
  }
  
  .view-mode-buttons {
    display: flex;
    gap: 10px;
    margin-top: 10px;
  }
  
  .view-mode-buttons button {
    padding: 10px 20px;
    border: none;
    border-radius: 4px;
    background-color: #007bff;
    color: white;
    cursor: pointer;
  }
  
  .view-mode-buttons button.active {
    background-color: #0056b3;
  }
  
  .view-mode-buttons button:hover {
    background-color: #0056b3;
  }
  
  .chart-overlay-container {
    position: relative;
    width: 100%;
    width: 400px; /* Voit muokata tarpeen mukaan */
    height: 400px; /* Skaalaa korkeuden suhteessa leveyteen */
  }
  
  @media (max-width: 480px) {
    .chart-overlay-container {
      width: 350px;
      height: 350px; 
    }
  }

  .scatter-chart {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
