/* Persoonan epätasapainon kartoitus CSS */

/* Container for the whole app */
.app-container {
    background-color: rgb(230, 215, 239);
    padding: 20px;
    text-align: left;
    max-width: 800px; /* Adjusted to fit more content */
    margin: 0 auto;
    box-shadow: 10px 10px 8px rgba(0, 0, 0, 0.2); /* Shadow on right and bottom */
    border-radius: 8px; /* Rounded corners */
    /* font-family: Arial, sans-serif; /* Default font */
  }
  .app-container button {
    padding: 10px 20px;
    margin-right: 10px;
    font-size: 16px;
    background-color: #620f8b;
    color: white;
    border: none;
    border-radius: 4px;
    cursor: pointer;
  }
  
  /* Question section */
  .question-container {
    margin-bottom: 30px; /* Spacing between question blocks */
  }
  
  .question-container h2 {
    font-size: 24px; /* Category title font size */
    color: #4c096c; /* Heading color to match button */
    margin-bottom: 10px;
  }
  
  .question-container p {
    font-size: 18px; /* Question font size */
    margin: 10px 0;
  }
  
  /* Buttons for answers */
  .question-container button {
    padding: 10px 20px;
    margin-right: 10px;
    font-size: 16px;
    background-color: #620f8b;
    color: white;
    border: none;
    border-radius: 4px;
    cursor: pointer;
  }
  
  .question-container button:hover {
    background-color: #4c096c;
  }
  
  /* Submit button */
  .submit-button {
    padding: 12px 25px;
    font-size: 18px;
    background-color: #620f8b;
    color: white;
    border: none;
    border-radius: 6px;
    cursor: pointer;
    margin-top: 20px;
  }
  
  .submit-button:hover {
    background-color: #4c096c;
  }
  
  /* Results section */
  .results-container {
    text-align: center;
  }
  
  .results-container h1 {
    font-size: 36px;
    color: #4c096c;
    margin-bottom: 20px;
  }
  
  .results-container p {
    font-size: 18px;
    margin: 10px 0;
  }
  
  /* Mobile layout */
  @media (max-width: 760px) {
    .app-container {
      padding: 30px;
      margin: 20px;
    }
  
    .question-container p {
      font-size: 16px;
    }
  
    .question-container button {
      width: 150px;
      padding: 8px;
      font-size: 14px;
    }
  
    .submit-button {
      width: 100%;
      padding: 10px;
      font-size: 16px;
    }
  
    .results-container h1 {
      font-size: 28px;
    }
  
    .results-container p {
      font-size: 16px;
    }
  }
  