



.profile-column-personal-imbalance {
    width: 100%;
}


/* Parent container for the image and the octagon */
.image-with-octagon {
    position: relative;  /* Allows the circles to be positioned over the image */
    display: inline-block;
}

/* Style for the image itself */
.styling-image {
    width: 100%;  /* Adjust the size of the image as needed */
    height: auto;
    display: block;
}

/* Octagon container, positioned absolutely over the image */
.octagon-container {
    position: absolute;
    top: 0px;
    left: 0px;
    width: 100%;  /* Full width of the parent container (image) */
    height: 100%; /* Full height of the parent container (image) */
    display: flex;
    justify-content: center;
    align-items: center;
    pointer-events: none;  /* Ensures the image remains clickable */
}


/* Keep the circle size at 40px */
.circle {
    position: absolute;
    width: 40px;  /* Keep the width of the circle */
    height: 40px; /* Keep the height of the circle */
    border-radius: 50%; /* Keeps the shape as a circle */
    background-color: rgb(182, 89, 225); /* Default color */
}

/* Positioning circles to form a symmetric octagon over the image */
.circle:nth-child(1) { top: 5%; left: 50%; transform: translate(-50%, 0); } /* Top center */
.circle:nth-child(2) { top: 22%; left: 78%; transform: translate(-50%, -50%); } /* Top right */
.circle:nth-child(3) { top: 50%; left: 90%; transform: translate(-50%, -50%); } /* Right center */
.circle:nth-child(4) { top: 78%; left: 78%; transform: translate(-50%, -50%); } /* Bottom right */
.circle:nth-child(5) { top: 95%; left: 50%; transform: translate(-50%, -100%); } /* Bottom center */
.circle:nth-child(6) { top: 78%; left: 22%; transform: translate(-50%, -50%); } /* Bottom left */
.circle:nth-child(7) { top: 50%; left: 10%; transform: translate(-50%, -50%); } /* Left center */
.circle:nth-child(8) { top: 22%; left: 22%; transform: translate(-50%, -50%); } /* Top left */

