/* Journal Container */
.journal-container {
  padding: 20px;
  max-width: 600px;
  margin: auto;
  background-color: #f9f9f9;
  border-radius: 8px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

.journal-header {
  text-align: center;
  margin-bottom: 20px;
  color: #333;
  font-size: 24px;
}

/* Create Button */
.btn-create-entry {
  display: inline-block;
  padding: 10px 15px;
  background-color: #28a745;
  color: white;
  border-radius: 5px;
  text-decoration: none;
  margin-bottom: 20px;
  font-size: 16px;
}

.btn-create-entry i {
  margin-right: 8px;
}

.btn-create-entry:hover {
  background-color: #218838;
}

/* Filter Section */

/* Filter Section */
.filters {
  display: flex; /* Use flexbox for side-by-side alignment */
  justify-content: space-between; /* Add space between the two sections */
  align-items: center; /* Align items vertically */
  margin-bottom: 20px;
  max-width: 600px; /* Match the max-width for consistency */
  margin: 0 auto; /* Center align the filter section */
}

.filter-group {
  flex: 1; /* Allow each group to take equal space */
  padding: 0 10px; /* Add some spacing between the groups */
  margin-bottom: 1rem;
}

.filter-group label {
  display: block; /* Full width */
  margin-bottom: 8px; /* Space below the label */
  font-size: 16px; /* Same font size as other labels */
  color: #333; /* Same color as other labels */
  text-align: left; /* Align the text to the left */
  font-weight: bold;
}

.filter-group select {
  width: 100%; /* Make the dropdown take full width of the group */
  max-width: 100%; /* No additional max-width to allow flex alignment */
  padding: 10px;
  border-radius: 5px;
  border: 1px solid #ccc;
  font-size: 16px;
  box-sizing: border-box; /* Ensures padding doesn't add to total width */
}

/* Journal Entries */
.journal-entries {
  display: grid;
  grid-template-columns: 1fr;
  gap: 20px;
}

/* Journal Card Layout */
.journal-card {
  background-color: white;
  padding: 20px;
  margin-bottom: 15px;
  border-radius: 8px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
}

.journal-title {
  margin: 0 0 10px;
  font-size: 22px;
  font-weight: bold;
  color: #333;
}

/* Metadata Section: Category and Date */
.journal-metadata {
  display: flex;
  justify-content: space-between;
  margin-bottom: 10px;
}

.journal-category,
.journal-date {
  font-size: 12px;
  color: #777;
}

.journal-category i,
.journal-date i {
  margin-right: 5px;
}

.journal-content {
  font-size: 16px;
  color: #555;
  margin-bottom: 15px;
  line-height: 1.45;
}

.journal-content p {
  margin: 0;
  padding: 0;
}

/* Action Buttons (Edit/Delete) */
.journal-actions {
  display: flex;
  justify-content: flex-start;
  gap: 10px;
}

.btn-edit,
.btn-delete {
  padding: 8px 12px;
  border-radius: 5px;
  border: none;
  cursor: pointer;
  font-size: 14px;
  transition: background-color 0.3s;
}

.btn-edit {
  background-color: #007bff;
  color: white;
}

.btn-edit:hover {
  background-color: #0056b3;
}

.btn-delete {
  background-color: #dc3545;
  color: white;
}

.btn-delete:hover {
  background-color: #c82333;
}

/* Journal Form Styles */
.form-control {
  width: 100%;
  max-width: 600px; /* Set a maximum width for better control */
  padding: 10px;
  font-size: 16px;
  box-sizing: border-box; /* Ensures padding doesn't add to total width */
  border: 1px solid #ccc; /* A consistent border */
}

textarea.form-control {
  height: 150px;
  resize: vertical; /* Allow resizing */
}

/* Journal Tag Styling */
.journal-tags {
  margin: 10px 0;
  display: flex;
  flex-wrap: wrap;
  gap: 5px;
}

.journal-tag {
  display: inline-block;
  background-color: #e0e0e0;
  color: #333;
  padding: 4px 8px;
  border-radius: 4px;
  font-size: 12px;
}

.journal-no-tags {
  font-size: 12px;
  color: #777;
}

/* Responsive Styles */
@media (max-width: 600px) {
  .journal-card {
    padding: 15px;
  }

  .journal-metadata {
    flex-direction: column;
    align-items: flex-start;
  }

  .journal-title {
    font-size: 20px;
  }

  .journal-content {
    font-size: 14px;
  }

  .btn-create-entry {
    /* make button to be in the middle */
    font-size: 16px;
    padding: 8px 12px;
  }

  .btn-edit,
  .btn-delete {
    font-size: 14px;
    padding: 6px 10px;
  }
}

/* Editor */
.card {
  border-radius: 10px; /* Rounded corners */
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1); /* Subtle shadow */

  background: linear-gradient(
    to bottom right,
    #ffffff,
    #f7f9fc
  ); /* Gradient background */
}

.p-editor-container {
  border: none !important; /* Remove default border */
  box-shadow: none !important; /* Remove default shadow */
}

.p-editor-content {
  font-family: "Roboto", sans-serif; /* Elegant font */
  font-size: 2rem !important; /* Adjust text size */
  line-height: 1.6; /* Better spacing */
  color: #333; /* Text color */
  background: #fafafa; /* Light gray background for the content area */
  padding: 10px; /* Add some padding */
  border-radius: 5px; /* Rounded corners for the text area */
  min-height: 320px; /* Ensure consistent height */
  border: 1px solid #dcdcdc; /* Subtle border */
  transition: border-color 0.3s ease, box-shadow 0.3s ease;
}

/* Focus effect */
.p-editor-content:focus-within {
  border-color: #007ad9; /* Blue border on focus */
  box-shadow: 0 0 5px #007ad9; /* Glow effect */
}

/* Target the Quill editor placeholder */
.ql-editor.ql-blank::before {
  content: "Start writing your journal entry..."; /* Placeholder text */
  color: #555; /* Placeholder color */
  font-size: 16px; /* Placeholder font size */
  line-height: 1.6; /* Placeholder line height */
  font: "Roboto", sans-serif; /* Placeholder font */
  font-style: normal !important; /* Override Quill's default italic style */
}

/* If your parent container is .ql-editor: */
.ql-editor ol li[data-list="bullet"] {
  list-style-type: disc !important; /* ensure bullet discs */
  margin-left: 1.5em; /* typical indent */
}

/* Optionally remove any default numbering marker if the browser tries to show it */
.ql-editor ol li[data-list="bullet"]::marker {
  content: "";
}

.journal-search {
  margin-bottom: 20px;
}

.highlight {
  background-color: yellow;
  font-weight: bold; /* Left this one */
}
