/* CSS for the different Pages */
/* Styling for various page containers */

/* General styling for all page containers */
.default-container {
  padding: 30px;
  max-width: 600px;
  margin: 50px auto; /* Center vertically and horizontally */
  background-color: #f9f9f9; /* Light background for contrast */
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.1); /* Soft shadow */
  border-radius: 12px; /* Rounded corners */
  transition: transform 0.3s, box-shadow 0.3s; /* Smooth animation effects */
  text-align: left; /* Center text for consistent design */
}

/* Hover effect for the containers */
.default-container:hover {
  transform: translateY(-5px); /* Slight lift effect */
  box-shadow: 0 15px 25px rgba(0, 0, 0, 0.2); /* Enhanced shadow on hover */
}

/* Specific styling for the Frontpage */
.frontpage-container {
  background-color: rgb(230, 215, 239); /* Light purple shade */
  padding: 20px;
  max-width: 700px;
  margin: 50px auto;
  box-shadow: 0 10px 15px rgba(0, 0, 0, 0.15); /* Subtle shadow */
  border-radius: 10px;
  transition: transform 0.3s, box-shadow 0.3s;
}

.frontpage-container:hover {
  transform: translateY(-5px);
  box-shadow: 0 15px 25px rgba(0, 0, 0, 0.2);
}

/* Specific styling for the Features page */
.features-container {
  padding: 30px;
  max-width: 600px;
  margin: 50px auto;
  background-color: #e8f5e9; /* Light green for a calm effect */
  box-shadow: 0 10px 15px rgba(0, 0, 0, 0.1);
  border-radius: 12px;
  transition: transform 0.3s, box-shadow 0.3s;
}

/* Hover effect for Features page */
.features-container:hover {
  transform: translateY(-5px);
  box-shadow: 0 15px 25px rgba(0, 0, 0, 0.2);
}

/* Headline styling for all pages */
.default-container h1 {
  font-size: 2.4rem;
  margin-bottom: 20px;
  color: #333; /* Dark color for contrast */
}

/* Text styling */
.default-container p {
  font-size: 1.2rem;
  line-height: 1.6;
  color: #555; /* Slightly darker shade for readability */
  margin-bottom: 20px;
}

.default-container-buttons {
  display: flex;
  justify-content: center;
}

/* Button styling */
.default-container .button {
  display: inline-block;
  padding: 12px 24px;
  margin-top: 20px;
  background-color: #620f8b; /* Accent color */
  color: white;
  border-radius: 5px;
  text-decoration: none;
  font-size: 1rem;
  transition: background-color 0.3s;
}

.default-container .button:hover {
  background-color: #4c096c; /* Darker shade on hover */
}

/* Responsive styles for smaller screens */
@media (max-width: 768px) {
  .default-container {
    padding: 20px;
    max-width: 90%;
  }

  .default-container h1 {
    font-size: 2rem;
  }

  .default-container p {
    font-size: 1rem;
  }

  .default-container-buttons {
    flex-direction: column;
    align-items: center;
  }
}
