/* ValueMapping.css */
h1 {
  font-size: 2em; /* Voit säätää fonttikokoa */
  margin: 20px 0; /* Voit säätää marginaaleja */
  text-align: center; /* Keskitetään otsikko */
  word-wrap: break-word; /* Sallitaan sanan katkaisu */
}



/* Value Mapping Container */
.value-mapping-container {
  padding: 30px;
  max-width: 600px;
  margin: 50px auto; /* Center vertically and horizontally */
  background-color: #f9f9f9; /* Light background for contrast */
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.1); /* Soft shadow */
  border-radius: 12px; /* Rounded corners */
  transition: transform 0.3s, box-shadow 0.3s; /* Smooth animation effects */
  text-align: left; /* Center text for consistent design */
}
.value-mapping-container:hover {
  transform: translateY(-5px); /* Slight lift effect */
  box-shadow: 0 15px 25px rgba(0, 0, 0, 0.2); /* Enhanced shadow on hover */
}

.value-mapping-container input {
  padding: 10px;
  margin-right: 10px;
  font-size: 16px;
  margin-bottom: 20px;
  margin-left: 10px;
}

/* .value-mapping-container label {
  font-size: 16px;
  font-weight: bold;
}
 */
.start-container {
  background-color: rgb(241, 230, 247);
  padding: 20px;
  text-align: left;
  max-width: 300px;
  /* margin: 0 auto; */
  margin-bottom: 20px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);  
  border-radius: 8px;
}

.guide-list {/* Aloitussivun lista */
  padding-left: 30px; 
}

/* Mobile layout */
@media (max-width: 760px) {
  h1 {
    font-size: 1.5em; /* Pienennetään fonttikokoa pienillä näytöillä */
    margin: 10px 0; /* Pienennetään marginaaleja pienillä näytöillä */
  }

  .value-mapping-container {
    background-color: #f9f9f9; /* Light background for contrast */
    box-shadow: 0 10px 20px rgba(0, 0, 0, 0.1); /* Soft shadow */
    transition: transform 0.3s, box-shadow 0.3s; /* Smooth animation effects */
    padding: 30px;
    margin: 30px;
    text-align: left;
    border-radius: 8px; /* Optional: Add rounded corners */
  }

  .value-mapping-container input {
    width: 160px; /* Adjust form width for smaller devices */
    padding: 10px;
    font-size: 14px;
    margin: 10px;
    margin-left: 0px;
    margin-bottom: 10px;
  }
  .review-value {
    flex-direction: column; /* Aseta flex-suunnaksi sarake */
    align-items: left; /* Kohdista sisältö vasemmalle */
    font-size: 16px; /* Pienennä fontin kokoa pienillä näytöillä */
    margin-left: 0px !important; /* Pienennä marginaalia pienillä näytöillä */
  }
  .review-value div {
    /* Aseta leveys 100% */
    width: 100%;
  }

  .button-remove {
    width: 100%; /* Aseta napin leveys 100% */
    margin-top: 10px; /* Lisää marginaalia ylös */
  }

}

/* Button styles */
.button {
  display: inline-block;
  width: auto !important; /* Aseta leveys automaattiseksi */
  padding: 10px 20px; /* Voit säätää paddingia */
  margin: 5px; /* Voit säätää marginaalia */
  background-color: #620f8b; /* Voit säätää taustaväriä */
  color: rgb(255, 255, 255); /* Voit säätää tekstin väriä */
  border: none; /* Voit säätää reunusta */
  border-radius: 4px; /* Voit säätää reunojen pyöristystä */
  cursor: pointer; /* Muuta kursori osoittamaan, että nappi on klikattavissa */
  font-weight: normal; /* Aseta tekstin paino normaaliksi */
  font-size: 16px;
}

.button-remove {
  display: inline-block;
  width: auto !important; 
  padding: 5px 10px; 
  margin: 5px; 
  background-color: #620f8b; 
  color: rgb(255, 255, 255); 
  border: none; 
  border-radius: 4px; 
  cursor: pointer; 
  font-weight: normal;
  font-size: 13px;
}

.button-remove:hover {
  background-color: #cc1a1a /* Voit säätää hover-tilan taustaväriä */
}

.button:hover {
  background-color: #4c096c /* Voit säätää hover-tilan taustaväriä */
}

.error-message {
  font-weight: bold;
  color: red;
}

.review-message {
  font-weight: bold;
  color: red;
}

.repeat-message {
  font-weight: bold;
  color: #620f8b;
}

.save-message {
  font-weight: bold;
  color: #620f8b;
}

/* .review-container {
  background-color: rgb(241, 230, 247); 
  padding: 10px; 
  border-radius: 5px; 
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1); 
  margin-top: 20px;
  margin-bottom: 20px;
} */

.review-value {
  display: flex;
  align-items: center;
  /* justify-content: space-between; */
  margin-left: 20px;
  font-weight: bold; /* Esimerkki: lihavoitu teksti */
  font-size: 20px; /* Esimerkki: fontin koko */
  color: #620f8b; /* Esimerkki: tekstin väri */
  position: relative;
  flex-wrap: nowrap;
}
.review-value::before {
  content: '•'; /* Palauttaa listan pallot */
  color: #000000; /* Pallon väri */
  position: absolute;
  left: -20px; /* Sijoittaa pallon vasemmalle */
}


.review-count-time {
  margin: 10px;
  font-weight: normal; /* Esimerkki: normaali teksti */
  font-size: 15px;
  color: #000000; /* Esimerkki: sininen väri */
}

.review-value form {
  margin: 0; /* Poistaa oletusmarginaalit */
}

/* .comparison-container {
  background-color: rgb(241, 230, 247); 
  padding: 10px; 
  border-radius: 5px; 
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1); 
  margin-top: 20px;
  margin-bottom: 20px; 
} */

.comparison-values {
  display: flex;
  align-items: center;
  justify-content: left;
  gap: 10px; /* Space between elements */
  margin-bottom: 30px;
}

.comparison-text {
  font-size: 20px; 
  font-weight: bold; 
  margin: 0; 
}

.button-comparison {
  padding: 5px 10px; 
  background-color: #620f8b; 
  color: rgb(255, 255, 255); 
  border: none; 
  border-radius: 4px; 
  cursor: pointer; 
  font-weight: normal;
}

.button-comparison:hover {
  background-color: #4c096c 
}

.result-container {
 /*  background-color: rgb(241, 230, 247);  */
  padding: 10px; 
  border-radius: 5px; 
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1); 
  margin-top: 20px;
  margin-bottom: 20px; 
}

.result-value {
  font-size: 18px; 
  font-weight: bold; 
  color: #620f8b; 
  margin: 0; 
}

.conversation-history {
  /* background-color: rgb(243, 240, 245);  */
  padding: 10px; 
  border-radius: 5px; 
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1); 
  margin-top: 20px; 
}