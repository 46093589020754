/* Course css file */

.courses-container {
  padding: 20px;
  max-width: 600px;
  margin: auto;
  background-color: #f9f9f9;
  border-radius: 8px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

/* Create Course Button Styling */
.create-course-button-container {
  text-align: right; /* Align the button to the right */
  margin-bottom: 20px; /* Add spacing below the button */
}

.btn-create-course {
  background-color: #28a745; /* Green background */
  color: white; /* White text */
  padding: 10px 20px; /* Add padding */
  border-radius: 5px; /* Rounded corners */
  text-decoration: none; /* Remove underline */
  font-size: 16px; /* Adjust font size */
  font-weight: bold;
  transition: background-color 0.3s; /* Smooth hover transition */
}

.btn-create-course:hover {
  background-color: #218838; /* Darker green on hover */
}

.course-actions {
  display: flex;
  gap: 10px; /* Space between buttons */
  margin-top: 20px; /* Add some space above */
}

/* Form Container */
.create-course-container {
  max-width: 600px;
  margin: 0 auto;
  padding: 20px;
  background-color: #f9f9f9;
  border-radius: 10px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

/* Form Groups */
.form-group {
  margin-bottom: 20px;
}

.form-group label {
  display: block;
  margin-bottom: 8px;
  font-weight: bold;
  color: #333;
}

.form-control {
  width: 100%;
  padding: 10px;
  border-radius: 5px;
  border: 1px solid #ccc;
  font-size: 16px;
}

/* Editor Styling */
.custom-editor {
  min-height: 200px;
  border: 1px solid #ccc;
  border-radius: 5px;
  padding: 10px;
}

.course-editor {
  background-color: #f9f9f9;
}

/* Form Actions */
.form-actions {
  text-align: right;
}

.btn-submit {
  background-color: #007bff;
  color: white;
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-size: 16px;
  font-weight: bold;
  transition: background-color 0.3s;
}

.btn-submit:hover {
  background-color: #0056b3;
}

.courses-header {
  text-align: center;
  margin-bottom: 20px;
  color: #333;
  font-size: 24px;
}

.course-detail-container {
  padding: 20px;
  max-width: 600px;
  margin: auto;
  background-color: #f9f9f9;
  border-radius: 8px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

.course-detail h2 {
  text-align: center;
  margin-bottom: 20px;
  color: #333;
  font-size: 24px;
}

/* BUTTONS IN COURSE DETAIL VIEW */

/* Shared Button Styles */
.btn-edit-course,
.btn-delete-course,
.btn-add-lesson {
  all: unset; /* Reset browser defaults for consistent styles */
  display: inline-block; /* Inline alignment */
  margin: 10px 0; /* Equal margins */
  padding: 10px 20px; /* Uniform padding */
  border-radius: 5px; /* Rounded corners */
  font-size: 16px; /* Same font size */
  font-weight: bold; /* Uniform boldness */
  text-align: center; /* Center-align text */
  text-decoration: none; /* No underline */
  font-family: "Arial", sans-serif; /* Specify consistent font */
  cursor: pointer; /* Pointer cursor */
  transition: background-color 0.3s; /* Smooth hover effect */
  min-width: 120px; /* Consistent button width */
  color: white; /* Default text color */
}

/* Edit Button Specific Styles */
.btn-edit-course {
  background-color: #007bff; /* Blue background */
  border: none; /* Ensure no border */
}

.btn-edit-course:hover {
  background-color: #0056b3; /* Darker blue on hover */
}

/* Delete Button Specific Styles */
.btn-delete-course {
  background-color: #dc3545; /* Red background */
  border: none; /* Ensure no border */
}

.btn-delete-course:hover {
  background-color: #c82333; /* Darker red on hover */
}

.btn-add-lesson {
  background-color: #28a745; /* Green background */
  border: none; /* Ensure no border */
}

.btn-add-lesson:hover {
  background-color: #218838; /* Darker green on hover */
}

/* Parent Container for Layout */
.course-actions {
  display: flex; /* Flexbox for alignment */
  gap: 10px; /* Space between buttons */
  margin-top: 20px; /* Add space above buttons */
}

/* for mobile */

@media (max-width: 600px) {
  .courses-container,
  .create-course-container,
  .course-detail-container {
    padding: 10px; /* Reduce padding */
  }

  .form-control {
    font-size: 14px; /* Smaller font size */
  }

  .custom-editor {
    min-height: 150px; /* Smaller editor height */
  }

  .btn-submit,
  .btn-edit-course,
  .btn-delete-course,
  .btn-add-lesson {
    padding: 8px 16px; /* Smaller padding */
    font-size: 14px; /* Smaller font size */
  }

  .course-actions {
    flex-direction: column; /* Stack buttons vertically */
    gap: 10px; /* Space between buttons */
  }
}

.lesson-container {
  display: block;
  padding-bottom: 20px;
  max-width: 600px;
  margin: 0 auto; /* Centers the element horizontally */
  background-color: #f9f9f9;
  border-radius: 8px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

.lesson-container h1 {
  text-align: left;
}

.lesson-image {
  max-width: 100%;
  height: auto;
  margin-bottom: 16px; /* Adds space between the image and title */
}

.lesson-image-above {
  display: block;
  margin: 0;
  width: 100%;
}

.lesson-image-below {
  display: block;
  margin-top: 1rem;
  margin-bottom: 1rem;
  width: 100%;
}

.lesson-below-part {
  display: block;
}

.ql-editor .ql-video {
  display: block;
  margin: 1em auto; /* Adjust margin as needed */
}

/* Editor */
.card {
  border-radius: 10px; /* Rounded corners */
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1); /* Subtle shadow */

  background: linear-gradient(
    to bottom right,
    #ffffff,
    #f7f9fc
  ); /* Gradient background */
}

.button-center {
  text-align: center; /* Centers inline/inline-block elements */
}

.cool-button {
  background-color: #28a745; /* A nice green shade */
  color: #ffffff;
  border: none;
  border-radius: 4px;
  padding: 10px 16px;
  font-size: 15px;
  font-weight: 600;
  cursor: pointer;

  /* Smooth transition for hover/active states */
  transition: background-color 0.3s ease, transform 0.1s ease;
}

/* Change color on hover */
.cool-button:hover {
  background-color: #218838; /* Darker green */
}

/* Slight “press” effect */
.cool-button:active {
  transform: scale(0.97);
}

/* Remove default focus outline and show a custom focus ring */
.cool-button:focus {
  outline: none;
  box-shadow: 0 0 0 3px rgba(40, 167, 69, 0.3);
}

.lesson-completed-message {
  display: inline-flex;
  align-items: center;
  background-color: #d4edda; /* Light green background for a success message */
  color: #155724; /* Dark green text */
  padding: 8px 12px;
  border: 1px solid #c3e6cb;
  border-radius: 4px;
  font-size: 16px;
}

.lesson-completed-icon {
  margin-right: 0.5rem;
  font-size: 18px; /* Slightly larger check mark */
}
