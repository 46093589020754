.container {
    padding: 1rem;
    max-width: 40rem;
    margin: 0 auto;
    background-color: #f9f9f9; 
    box-shadow: 0 10px 20px rgba(0, 0, 0, 0.1); 
    border-radius: 12px; 
    transition: transform 0.3s, box-shadow 0.3s; 
    text-align: left; 
  }
  
  .title-h1 {
    font-size: 2rem;
    font-weight: bold;
    /* color: black; */
    margin-bottom: 1rem;
  }
  .title-h2 {
    font-size: 1.5rem;
    font-weight: bold;
    /* color: black; */
    margin-bottom: 0;
  }
  
  .subtitle {
    font-size: 0.875rem;
    color: #6b7280;
    margin-bottom: 1rem;
  }

  .text {
    font-size: 1rem;
    /* color: #6b7280; */
    margin-bottom: 1rem;
  }

  .text-example {
    font-size: 0.9rem;
    font-style: italic;
  }
  
  .link {
    text-decoration: underline;
    color: #3b82f6;
  }
  
  .form {
    display: flex;
    flex-direction: column;
    gap: 1rem;
  }
  
  .label {
    font-weight: bold;
    display: block;
    margin-bottom: 0.5rem;
  }
  
  .input,
  .textarea {
    width: 100%;
    padding: 0.5rem;
    border: 1px solid #d1d5db;
    border-radius: 0.375rem;
  }
  
  .textarea {
    resize: vertical;
  }
  
  .button {
    width: 100%;
    margin-top: 1rem;
  }