/* MESSAGE TEXT STYLING */
/* EXAMPLE USAGE: <div class="message error">An error occurred. Please try again.</div> */
/* FIRST USE 'message' THEN 'which message this is about' */

/* Base Style for All Messages */
.message {
  max-width: 600px;
  padding: 15px;
  margin: 20px auto;
  font-size: 18px;
  font-weight: bold;
  border-radius: 4px;
  box-shadow: 2px 2px 8px rgba(0, 0, 0, 0.1);
  text-align: left;
  display: flex;
  align-items: center;
  gap: 10px; /* Space between icon and text */
  border-left: 6px solid;
  opacity: 1; /* Fully visible by default */
  transition: opacity 2s ease-in-out; /* Smooth fade-out effect */
}

/* Fade-Out Class */
.fade-out {
  opacity: 0; /* Fully transparent when fade-out is active */
}

/* Error Message */
.error {
  background-color: #f8d7da; /* Light red background */
  color: #842029; /* Dark red text */
  border-left-color: #dc3545; /* Bold red border */
}

.error::before {
  content: "❌"; /* Error icon */
  font-size: 24px;
}

/* Success Message */
.success {
  background-color: #d4edda; /* Light green background */
  color: #155724; /* Dark green text */
  border-left-color: #28a745; /* Bold green border */
}

.success::before {
  content: "✅"; /* Success icon */
  font-size: 24px;
}

/* Warning Message */
.warning {
  background-color: #fff3cd; /* Light yellow background */
  color: #856404; /* Dark yellow/brown text */
  border-left-color: #ffc107; /* Bold yellow border */
}

.warning::before {
  content: "⚠️"; /* Warning icon */
  font-size: 24px;
}

/* Info Message */
.info {
  background-color: #d1ecf1; /* Light blue background */
  color: #0c5460; /* Dark blue text */
  border-left-color: #17a2b8; /* Bold blue border */
}

.info::before {
  content: "ℹ️"; /* Info icon */
  font-size: 24px;
}
