/* TextDisplayer.css */

/* Hide the entire toolbar if it’s still showing up somehow */
.p-editor-toolbar {
  display: none !important;
}

/* Remove border, padding, etc. from the editor container */
.p-editor-container {
  border: none !important;
  box-shadow: none !important;
  padding: 0 !important;
}

/* Let the content size naturally, remove extra spacing */
.p-editor-content {
  min-height: auto !important;
  padding: 0 !important;
}
