/* NAVIGATION MENU */

/* Logo styling */
.logo {
  height: 40px; /* Set a fixed height */
  width: auto;
  margin-left: 20px;
  max-width: 100px;
}

/* ---------- General Navigation Bar Styles ---------- */
nav {
  background-color: #000;
  padding: 10px 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 1000;
  border-bottom: 5px solid #620f8b;
}

/* ---------- Menu Styling ---------- */
.menu {
  display: flex;
  justify-content: space-around;
  align-items: center;
  flex-grow: 1;
}

.menu.hide {
  display: none; /* Hide the menu by default on small screens */
}

/* Styling for the navigation links */
nav a {
  margin: 0 10px;
  text-decoration: none;
  color: white;
  font-size: 18px;
  font-weight: bold;
  transition: color 0.3s ease;
}

nav a:hover {
  color: #ff0000;
}

/* ---------- Hamburger Menu Styling (For Small Screens) ---------- */
.hamburger {
  display: none; /* Hidden by default */
  flex-direction: column;
  cursor: pointer;
  margin-right: 20px;
  z-index: 2001;
}

.hamburger div {
  width: 25px;
  height: 3px;
  background-color: white;
  margin: 5px 0;
  transition: 0.3s ease;
}

/* Animations for the hamburger menu when open */
.hamburger.open div:nth-child(1) {
  transform: rotate(-45deg) translate(-5px, 5px);
}

.hamburger.open div:nth-child(2) {
  opacity: 0;
}

.hamburger.open div:nth-child(3) {
  transform: rotate(45deg) translate(-5px, -5px);
}

/* ---------- Media Query for Small Screens ---------- */
@media (max-width: 768px) {
  .hamburger {
    display: flex; /* Show the hamburger icon on small screens */
  }

  .menu {
    display: none;
    flex-direction: column;
    position: absolute;
    top: 60px;
    left: 0;
    width: 100%;
    background-color: #000;
    padding: 20px 0;
  }

  .menu.open {
    display: flex; /* Show the menu when the hamburger is clicked */
  }

  nav a {
    margin: 10px 0;
    text-align: center;
    width: 100%; /* Ensure links take full width */
  }
  .dropbtn {
    font-size: 18px !important;
  }
}

/* ---------- Logout Button Styling ---------- */
.logout-btn {
  background-color: #000;
  color: white;
  padding: 8px 16px;
  border: 2px solid #ff0000;
  border-radius: 4px;
  cursor: pointer;
  font-size: 18px;
  font-weight: bold;
  transition: background-color 0.3s ease, color 0.3s ease;
  font-family: inherit;
}

.logout-btn:hover {
  background-color: #ff0000;
  color: black;
}

/* ---------- Body Padding (Avoid Content Overlap with Navbar) ---------- */
body {
  margin-top: 60px; /* Push content down so it doesn't overlap with the fixed navbar */
}

/* ---------- Dropdown Menu Styling ---------- */
.dropdown {
  position: relative; /* Ensures dropdown content is positioned relative to this element */
  display: inline-block;
}

.dropbtn {
  color: white;
  background-color: black;
  padding: 10px 20px;
  font-size: 18px;
  font-weight: bold;
  border: none;
  cursor: pointer;
  width: 100%;
  font-family: inherit;
}

.dropbtn:hover {
  color: #ff0000;
}

/* Dropdown content, hidden by default */
.dropdown-content {
  display: none;
  position: absolute; /* Positioned relative to the dropdown container */
  background-color: white;
  min-width: 160px;
  box-shadow: 0px 8px 16px rgba(0, 0, 0, 0.2);
  z-index: 1;
  padding: 10px 0;
  margin-top: 10px;
  box-sizing: border-box;
}

/* Links inside the dropdown */
.dropdown-content a {
  color: black;
  padding: 12px 16px;
  text-decoration: none;
  display: block;
  font-size: 16px;
  font-weight: bold;
  text-align: left;
  box-sizing: border-box;
}

.dropdown-content a:hover {
  background-color: #f1f1f1;
}

/* Show the dropdown content when the menuOpen class is active */
.show {
  display: block; /* Makes the dropdown visible */
}

/* ---------- Dropdown Menu Styling for Small Screens ---------- */
@media (max-width: 768px) {
  .dropdown {
    position: relative; /* Ensures dropdown content is positioned relative to this element */
    display: inline-block;
  }
  .dropbtn {
    /* make it into center */
    display: flex;
    justify-content: center;
    color: white;
    padding: 10px 20px;
    font-size: 16px;
    font-weight: bold;
    border: none;
    cursor: pointer;
  }
  .dropdown-content {
    display: none;
    position: static; /* Positioned static to expand the dropdown container in mobile */
  }

  .dropdown-content.show {
    display: block;
  }
}

/* ######################## */

/* Homepage view  */

.home-container {
  padding: 20px;
  text-align: left;
  max-width: 600px;
  margin: 0 auto;
}

.home-container h1 {
  font-size: 2.5rem;
  margin-bottom: 20px;
}

@media (max-width: 768px) {
  .home-container {
    padding: 0px;
    margin: 0px;
    width: 100%;
  }
  .home-container h1 {
    font-size: 2rem;
  }
}

.language-switcher {
  display: flex;
  gap: 20px;
  margin-right: 30px;
}

.language-switcher .active {
  border: 2px solid #000;
  border-radius: 50%;
}
