.logout-warning {
    position: fixed;
    bottom: 10px;
    right: 10px;
    padding: 20px;
    background-color: red;
    color: white;
    z-index: 1000;
    border-radius: 5px;
  }
  
  .logout-warning button {
    margin-top: 10px;
    padding: 5px 10px;
    background-color: white;
    color: red;
    border: none;
    border-radius: 3px;
    cursor: pointer;
  }