/* File: PagesHome.css */
/* ------------------------------------------ */
html,
body {
  padding: 0;
  margin-left: 0;
  margin-bottom: 0;
  width: 100%;
}

.home-wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  width: 100%;
  left: 0;
}

/* HERO SECTION */
.hero-section {
  width: 100%;
  padding: 80px 20px;
  text-align: center;
  color: #fff;
  position: relative;
}

.hero-text {
  max-width: 600px;
  height: 160px;
  margin: 0 auto;
  background: linear-gradient(
    to right,
    transparent,
    rgba(0, 0, 0, 0.4),
    rgba(0, 0, 0, 0.5),
    rgba(0, 0, 0, 0.4),
    transparent
  );
}

.hero-text h1 {
  font-size: 3rem;
  margin-top: 100px;
  margin-bottom: 10px;
  line-height: 2;
}

.hero-text p {
  color: rgba(255, 255, 255, 0.9);
  font-size: 1.2rem;
  max-width: 500px;
  margin: 0 auto;
}

.hero-cta {
  margin-top: 40px;
}

.cta-button {
  background-color: #620f8b;
  color: #fff;
  padding: 15px 30px;
  font-size: 1rem;
  border: none;
  border-radius: 50px;
  text-decoration: none;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.cta-button:hover {
  background-color: #4c096c;
}

/* FEATURES SECTION */
.features-section {
  width: 100%;
  max-width: 1200px;
  margin: 30px auto;
  text-align: left;
  padding: 5px;
}

.features-section h2 {
  font-size: 2rem;
  text-align: center;
  margin-bottom: 30px;
}

.section-intro {
  margin: 0 auto 40px;
  max-width: 700px;
}

.features-list {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 2rem;
}

.feature-item {
  background-color: #fff;
  border-radius: 10px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.06);
  padding: 20px;
  max-width: 300px;
  flex: 1 1 250px;
  text-align: left;
}

.feature-icon {
  font-size: 2.5rem;
  margin-bottom: 15px;
  color: #620f8b;
}

.feature-item h3 {
  margin-bottom: 10px;
  font-size: 1.3rem;
}

/* TESTIMONIAL SECTION */
.testimonials-section {
  width: 100%;
  background-color: #fff;
  padding: 20px 20px;
  text-align: center;
}

.testimonials-section h2 {
  font-size: 2rem;
  margin-bottom: 20px;
}

.testimonials-list {
  display: flex;
  flex-direction: column;
  gap: 2rem;
  margin: 0 auto;
  max-width: 700px;
}

.testimonial-item {
  background-color: #f9f9f9;
  border-left: 5px solid #620f8b;
  padding: 20px;
  border-radius: 5px;
  text-align: left;
}

.testimonial-text {
  font-style: italic;
  margin-bottom: 10px;
}

.testimonial-user {
  font-weight: bold;
  text-align: right;
}

/* CTA SECTION */
.cta-section {
  width: 100%;
  height: 240px;
  color: #fff;
  padding: 40px 20px;
  text-align: center;
  background-color: #620f8b;
}

.cta-section h2 {
  font-size: 2rem;
  margin-top: 0;
  margin-bottom: 20px;
}

.cta-section p {
  max-width: 600px;
  margin: 0 auto 40px;
}

.cta-button.cta-button-secondary {
  background-color: #fff;
  color: #4c096c;
  border: 1px solid #fff;
}

.cta-button.cta-button-secondary:hover {
  background-color: #ffe9e0;
  color: #4c096c;
}

/* FOOTER SECTION */
.footer-section {
  width: 100%;
  background-color: #323232;
  color: #ccc;
  padding: 20px;
  text-align: center;
  font-size: 0.9rem;
}

/* ------------------------------------------ */
/*          RESPONSIVE MEDIA QUERIES         */
/* ------------------------------------------ */

/* Target tablets and below (max-width: 768px) */
@media (max-width: 768px) {
  .hero-text h1 {
    font-size: 2.5rem; /* Slightly smaller on tablets */
    margin-top: 80px; /* Adjust the top margin */
  }

  .hero-text p {
    margin-bottom: 30px;
  }

  .cta-button {
    font-size: 0.9rem; /* Slightly smaller on tablets */
    padding: 12px 24px;
    margin-top: 40px;
  }

  .features-list {
    flex-direction: column; /* Stack features vertically */
    gap: 1rem;
  }

  .feature-item {
    max-width: 100%; /* Let it fill the container's width */
  }
}

/* Target phones and smaller devices (max-width: 576px) */
@media (max-width: 576px) {
  .cta-button {
    font-size: 0.95rem;
    padding: 10px 20px;
  }

  .features-section {
    margin: 30px auto; /* less vertical margin */
    padding: 0 10px; /* narrower side padding */
  }

  .section-intro {
    padding-left: 5px; /* reduce padding */
    padding-right: 5px; /* reduce padding */
  }

  /* Make sure each item fits comfortably on screen */
  .features-list {
    flex-direction: column;
    gap: 1.25rem; /* reduce the gap further on tiny screens */
  }

  .feature-item {
    max-width: 100%;
    padding: 10px; /* reduce padding so the card stays visible without overflow */
    min-height: auto; /* Ensure it doesn't force extra height */
    flex: unset; /* Removes the flexible sizing */
    width: 100%; /* Ensures it takes only required height */
  }

  /* Optional: reduce font sizes a bit for each feature item */
  .feature-item h3 {
    font-size: 1.3rem;
  }

  .feature-item p {
    font-size: 1rem; /* if needed */
  }

  .testimonials-section {
    padding: 10px 10px 40px 10px;
  }

  .cta-section {
    padding: 40px;
    text-align: left;
    min-height: 315px;
    height: auto;
  }
}
