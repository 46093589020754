/* Settings.css */
.settings-container {
  padding: 30px;
  max-width: 400px;
  margin: 50px auto; /* Center vertically and horizontally */
  background-color: #f9f9f9; /* Light background for contrast */
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.1); /* Soft shadow */
  border-radius: 12px; /* Rounded corners */
  transition: transform 0.3s, box-shadow 0.3s; /* Smooth animation effects */
  text-align: left; /* Center text for consistent design */
  }
/*   .settings-container:hover {
    transform: translateY(-5px); /* Slight lift effect 
    box-shadow: 0 15px 25px rgba(0, 0, 0, 0.2); /* Enhanced shadow on hover 
  }
   */
  .settings-button {
    background-color: #af4c4c;
    color: white;
    padding: 10px 20px;
    border: none;
    cursor: pointer;
    border-radius: 4px;
    font-size: 16px;
  }
  
  .settings-button:hover {
    background-color: #45a049;
  }
  
  .settings-form-group {
    margin-bottom: 15px;
  }
  
  .settings-form-group label {
    display: block;
    margin-bottom: 5px;
    font-weight: bold;
  }
  
  .settings-form-group input {
    width: 100%;
    padding: 8px;
    box-sizing: border-box;
    border: 1px solid #ccc;
    border-radius: 4px;
  }

  .settings-message {
    font-weight: bold;
    color: green;
  }
  
  .error-message {
    font-weight: bold;
    color: red;
  }