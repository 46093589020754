.cookie-consent {
    position: fixed;
    bottom: 0;
    width: 100%;
    background-color: #2B373B;
    color: white;
    text-align: center;
    padding: 15px;
    box-shadow: 0 -2px 10px rgba(0, 0, 0, 0.1);
    z-index: 1000;
    left: 0;
    margin: 0 auto;
  }
  
  .cookie-consent p {
    margin: 0;
    padding: 0;
    display: inline;
  }
  
  .cookie-consent a {
    color: #FFD700;
    text-decoration: underline;
  }
  
  .cookie-consent button {
    background-color: #FFD700;
    color: #2B373B;
    border: none;
    padding: 10px 20px;
    margin-left: 15px;
    margin-top: 10px;
    cursor: pointer;
    font-size: 14px;
    border-radius: 5px;
  }
  
  .cookie-consent button:hover {
    background-color: #FFC107;
  }

  /* Media query for small screens */
  @media (max-width: 768px) {
    .cookie-consent {
        padding: 10px;
        
    }

    .cookie-consent p {
        display: block;
        margin-bottom: 10px;
        padding: 10px;
    }

    .cookie-consent button {
        display: block;
        width: 80%;
        margin: 0 auto;
        margin-top: 10px;
    }
  }

  .modal {
    position: fixed;
    z-index: 1000;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0,0,0,0.5);
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .modal-content {
    color: black;
    background-color: white;
    padding: 20px;
    border-radius: 10px;
    width: 80%;
    max-width: 500px;
  }
  
  .close {
    position: absolute;
    top: 10px;
    right: 20px;
    font-size: 24px;
    cursor: pointer;
  }
  
  .modal-buttons {
    text-align: center;
    margin-top: 20px;
  }
  
  .modal-buttons button {
    margin: 0 auto;
    display: block;
  }