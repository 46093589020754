/* PROFILE SECTION IS HERE */

/* Container for the four columns */
.profile-container {
  display: grid;
  grid-template-columns: repeat(4, 1fr); /* Four equal columns */
  gap: 20px; /* Adds spacing between the columns */
  padding: 20px;
  width: 100%; /* Make sure the container takes full width */
  box-sizing: border-box; /* Ensure padding and borders are included in width */
}

/* Individual profile columns */
.profile-column {
  background-color: #f9f9f9;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  overflow: hidden; /* Ensure content inside doesn't overflow */
  word-wrap: break-word; /* Break long words or URLs */
  min-width: 0; /* Prevent columns from expanding too much */
}

/* Styling for internal sections (Character Strengths, Weaknesses, Goals, Pain Points) */
.character-container {
  background-color: #fff;
  padding: 15px;
  margin-bottom: 20px;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  word-wrap: break-word; /* Ensure text wraps properly */
}

/* Styling loading text */
.loading {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  font-size: 24px;
  position: relative;
}

/* Responsive Design */
/* Reduce to 3 columns on medium-sized screens */
@media (max-width: 1200px) {
  .profile-container {
    grid-template-columns: repeat(3, 1fr); /* Three equal columns */
  }
}

/* Reduce to 2 columns on tablets */
@media (max-width: 1024px) {
  .profile-container {
    grid-template-columns: repeat(2, 1fr); /* Two equal columns */
  }
}

/* Stack into a single column on smaller screens (mobile) */
@media (max-width: 768px) {
  .profile-container {
    grid-template-columns: 1fr; /* One column for small screens */
  }
}
